import React from "react";
import { graphql } from 'gatsby'
import { resetWebflow, createHubSpotForm, getSafe } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import VideoFrame from "../components/video-demo/video";
import Notification from "../components/header/notification";
import Layout from "../layouts";
//// DEVELOPMENT
// import $ from 'jquery';

export default ({
  data: {
    datoCmsDemo,
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  const goBack = () => {
    try {
      try {
        window.location.href = "/video-demos";
      } catch (error) {
        window.location.href = "/";
      }
    } catch (error) {}
  };

  if (datoCmsDemo.premium) {
    createHubSpotForm({
      targetId: "#PremiumHubSpot",
      formId: datoCmsDemo.premiumHubspotForm,
      css: ""
    });
    try {
      setTimeout(() => {
        getSafe(() => $("#VideoDemoPremiumForm").fadeIn());
      });
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          $("#VideoDemoPremiumForm").fadeOut();
        }
      });
    } catch (error) {}
  }

  resetWebflow("5bafa2e457091379321abc2f");
  return (
    <Layout>
      <Notification notifications={notifications} linkedPage={datoCmsDemo.notificationPage}></Notification>
      <div className="tint-nugit-popup" id="VideoDemoPremiumForm">
        <a href="#" class="back-button w-button" onClick={goBack}>
          Go Back
        </a>
        <div className="popup-bg form">
          <div className="flex-container space-between">
            {datoCmsDemo.premiumHeading && (
              <h5>{datoCmsDemo.premiumHeading}</h5>
            )}
            <div className="sm-spacing" />
            <div className="w-embed w-script">
              <div id="PremiumHubSpot" />
            </div>
          </div>
        </div>
      </div>
      <HelmetDatoCms seo={datoCmsDemo.seoMetaTags} />
      <VideoFrame
        title={datoCmsDemo.title}
        subtitle={datoCmsDemo.subtitle}
        videoUrl={datoCmsDemo.video && datoCmsDemo.video.url}
        displaySocialShare={true}
      />
    </Layout>
  );
};

export const query = graphql`
  query VideoDemoQuery($slug: String!) {
    datoCmsDemo(slug: { eq: $slug }) {
      id
      title
      subtitle
      video {
        providerUid
        url
      }
      premiumHeading
      premiumHubspotForm
      premium
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
